*,
::before,
::after {
  border-width: 0;
}

/* //fix quill content img */
.quillx img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.description-preview img {
  display: none;
}
.modal-body {
  max-height: 70vh; /* Thay đổi chiều cao tối đa theo nhu cầu */
  overflow-y: auto; /* Cho phép cuộn dọc */
}

/* /////////////////////end quill custom */

.custom-modal {
  max-height: 90vh; /* Chiều cao tối đa của modal */
  overflow-y: auto; /* Thêm thanh cuộn nếu nội dung vượt quá chiều cao tối đa */
}
.description-cell {
  max-width: 200px; /* Chiều rộng tối đa của ô */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  line-height: 1.5;
}

.table-wrapper .table tbody td {
  font-size: 12px; /* Giảm kích thước chữ trong các ô của tbody */
}

.table-wrapper .table th {
  font-size: 12px; /* Giảm kích thước chữ trong các ô của tbody */
}

.description-preview {
  display: -webkit-box;
  -webkit-line-clamp: 4; /* Giới hạn 4 dòng */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.login-box {
  margin-top: 75px;
  background: #ffffff;
  text-align: center;

  border-radius: 15px;
}
.login-title {
  text-align: center;
  font-size: 30px;
  letter-spacing: 2px;
  margin-top: 35px;
  font-weight: bold;
  color: #000000;
}
.login-form {
  margin-top: 25px;
  text-align: left;
}

.form-control:focus {
  box-shadow: none;
  background-color: #1a2226;
  color: #ecf0f5;
}
.form-control-label {
  font-size: 15px;
  color: #6c6c6c;
  letter-spacing: 1px;
}
.btn-outline-primary {
  width: 200px;
  height: 50px;
  border-radius: 15px;
  cursor: pointer;
  border-color: #0db8de !important;
  color: #0db8de;
  margin-top: 30px;
}
.btn-outline-primary:hover {
  background-color: #071014 !important;
  background-image: -webkit-linear-gradient(
    160deg,
    #071014 0%,
    #0db8de 100%
  ) !important;
  background-image: -moz-linear-gradient(
    160deg,
    #071014 0%,
    #0db8de 100%
  ) !important;
  background-image: -o-linear-gradient(
    160deg,
    #071014 0%,
    #0db8de 100%
  ) !important;
  background-image: linear-gradient(
    160deg,
    #071014 0%,
    #0db8de 100%
  ) !important;

  color: #ffffff !important;
}
.login-button {
  padding-right: 0px;
  text-align: right;
  margin-bottom: 25px;
}
.login-text {
  padding-left: 0px;
  color: #a2a4a4;
}

/* ///////////////////report err */

#error-report-icon {
  position: fixed;
  right: 20px;
  bottom: 20px;
  background-color: #ff6b6b;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-weight: bold;
  transition: background-color 0.3s;
  z-index: 999;
}
#error-report-icon:hover {
  background-color: #ff4c4c;
}

#error-report-form {
  position: fixed;
  right: 20px;
  bottom: 70px;
  width: 300px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  z-index: 1000;
}

#error-report-form h3 {
  margin-top: 0;
  color: #ff6b6b;
}

#error-report-form input,
#error-report-form textarea {
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
}

#error-report-form button {
  background-color: #ff6b6b;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

#error-report-form button[type='button'] {
  background-color: gray;
  margin-left: 10px;
}

/* ////////////////////////////////bug err */

.textarea-container {
  position: relative;
  width: 100%;
}

textarea {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  resize: none;
}

.character-counter {
  position: absolute;
  bottom: 23px; /* Căn dưới cùng */
  right: 10px; /* Căn phải */
  font-size: 12px;
  color: gray;
  pointer-events: none; /* Không cản trở thao tác của người dùng */
}

/* ///SWAPALERT custom*/
.swal2-actions .swal2-cancel,
.swal2-actions .swal2-confirm {
  margin-left: 20px;
}

.blinking {
  animation: blink 1s linear infinite;
  background-color: #ff3737 !important;
  color: white !important;
}

@keyframes blink {
  50% {
    opacity: 0.5;
  }
}

/* Định nghĩa style cho dark-mode */
/* globals.css */
html.light {
  background-color: white;
  color: black;
}

html.dark {
  background-color: black;
  color: white;

  .content-wrapper {
    background-color: black !important;
    color: #ffffff !important;
  }
  .sidebar {
    background-color: black !important;
    color: #ffffff !important;
  }
  .sidebar .nav .nav-item .nav-link .menu-title {
    color: #ffffff !important;
  }
  .bg-white {
    background-color: black !important;
    color: #ffffff !important;
  }
  .skycom .table-bordered th,
  .table-bordered td {
    color: #ffffff !important;
  }
  .skycom .form-control {
    background-color: black !important;
    color: #ffffff !important;
  }
  .navbar .navbar-menu-wrapper {
    background-color: black !important;
    color: #ffffff !important;
  }
  .navbar .navbar-brand-wrapper {
    background-color: black !important;
    color: #ffffff !important;
  }
  .skycom .footer {
    background-color: black !important;
    color: #ffffff !important;
  }
  .form-select {
    background-color: black !important;
    color: #ffffff !important;
  }
}

/* Custom styles for theme switcher */
.form-switch {
  display: inline-flex;
  align-items: center;
}

.form-check-input {
  width: 2rem;
  height: 1rem;
  background-color: #ddd;
  border-radius: 1rem;
  position: relative;
  cursor: pointer;
}

.form-check-input:checked {
  background-color: #007bff;
}
